:root {
  --swiper-theme-color: #202356;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Scrollbar Styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Firefox scrollbar styling */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

@layer components {}

.sidebar .nav-link.active {
  @apply bg-gradient-to-tr to-[#1754FC1A] from-[#C06EEC1A] text-current
}

/*
    .sidebar .nav-link.active span{
      @apply bg-gradient-to-tr from-gradientColor-1000 to-gradientColor-2000 !bg-clip-text text-transparent
    } */
/*
    .sidebar .nav-link.active svg{
      @apply bg-gradient-to-tr from-gradientColor-1000 to-gradientColor-2000
    } */



.create-btn:hover span {
  color: white;
}



.swiper-button-next,
.swiper-button-prev {
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  background-color: #fff;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

.teams-swiper .swiper-pagination-bullet {
  background-color: #fff;
  height: 0.8rem;
  width: 0.8rem;
}



/* == */

/* ----------------------------------------------
 * Generated by Animista on 2021-1-21 2:25:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

body,
main,
section {
  -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in {
  -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.overlay {
  background: #222222;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  opacity: 0.5;
  z-index: 5000;
  width: 100%;
}

.spinner {
  margin: auto;
}

/**
  * ----------------------------------------
  * animation fade-in
  * ----------------------------------------
  */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show-mobile-sidebar .mobile-sidebar {
  left: 0px !important;
}


.menu {
  @apply items-center justify-center gap-12;


}

.bg-grad {
  background: var(--1, linear-gradient(to bottom right, rgba(156, 104, 239, 0.05) 20%, rgba(23, 84, 252, 0.05) 49%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(156, 104, 239, 0.05) 20%, rgba(23, 84, 252, 0.05) 49%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(156, 104, 239, 0.05) 20%, rgba(23, 84, 252, 0.05) 49%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(156, 104, 239, 0.05) 20%, rgba(23, 84, 252, 0.05) 49%) top right / 50% 50% no-repeat);
}

.menuSidebar li a.active,
.menuSidebar li a:hover {
  color: #1754FC;
  background: -webkit-linear-gradient(#C06EEC, #1754FC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: #ECE7F6;
}

.menuSidebar li a[activeclassname="active"] {
  background: #ECE7F6;



}

.showMenu .sidebar {
  width: 80px;
}

.showMenu .sidebar .logo {
  height: 27px;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Changed from center to flex-start */
  align-items: center;
  padding-left: 1rem; /* Add padding to align with ul components */
}

.showMenu .sidebar .logo a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showMenu .sidebar .logo a svg {
  display: block;
  width: 24px; /* Adjust this value as needed */
  height: 24px; /* Adjust this value as needed */
}

.showMenu .sidebar .logo a img:not(svg) {
  display: none;
}

.showMenu .sidebar ul li a .text {
  display: none;
}

.showMenu .sidebar .fixed {
  width: auto;
}

.showMenu .logout ul li button .text {
  display: none !important;
}

.showMenu .sidebar ul {
  width: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    display: none !important;
}

.bg-onboarding-gradient {
  background: linear-gradient(rgb(55, 89, 249), rgb(164, 106, 239));
}

@layer utilities {

  /* Add this new class */
  .hidden-when-sidebar-collapsed {
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
  }
}

/* Add this new rule */
.showMenu .hidden-when-sidebar-collapsed {
  max-width: 0;
  max-height: 0;
  opacity: 0;
}

/* Add these new rules */
.showMenu .sidebar ul li .nav-link {
    padding-left: 1rem;  /* Reduce padding when collapsed */
    padding-right: 1rem;
    width: 48px;  /* Fixed width when collapsed */
}

.showMenu .logout ul li .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 48px;
}

.radial-bg {
  background: radial-gradient(167.74% 69.03% at 44% 64%, rgba(156, 104, 239, 0.05) 39.58%, rgba(23, 84, 252, 0.05) 98.399%);
}

.grayscale {
  filter: grayscale(100%);
}

.animate-fade-in-scale {
  animation: fadeInScale 700ms ease-out;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.webkit-10 {
  -webkit-backdrop-filter: blur(10px);
}